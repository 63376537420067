<template>
  <div class="domain_finder">
    <h1>{{ $t('system.domain_finder') }}</h1>
    <p>{{ $t('views.tools.domain_finder.description') }}</p>
    <p>{{ $t('views.tools.domain_finder.supported_domains') }}
      <ul>
        <li v-for="(_,d) in supported_domains" v-bind:key="d"><code>{{ d }}</code></li>
      </ul>
    </p>

    <Loading v-if="request_pending"></Loading>

    <b-alert
        :show="parent_found === true && domain_found === false"
        dismissible
        :variant="present_parent in supported_domains?'success':'warning'">
      <p v-html="$t('views.tools.domain_finder.domain_available', {domain: domain_requestable + (normalize_fdqn(domain_requestable)!=domain_requestable+'.'?' ('+ normalize_fdqn(domain_requestable) + ') ':'') })"/>
      <template v-if="present_parent && present_parent in supported_domains">
        <p v-html="$t('views.tools.domain_finder.contact', supported_domains[present_parent])"/>
      </template>
      <p v-else v-html="$t('views.tools.domain_finder.contact_default', {domain: present_parent})"/>
    </b-alert>
    <b-alert
        :show="domain_found === true"
        dismissible
        variant="danger">
      {{ $t('views.tools.domain_finder.domain_taken') }}
      <p v-if="!is_requestable">
        {{ $t('views.tools.domain_finder.contact_default_taken') }}
      </p>
    </b-alert>
    <b-alert
        :show="parent_found === false && domain_found === false"
        dismissible
        variant="warning">
      <p>{{ $t('views.tools.domain_finder.domain_unclear') }}</p>
      <p v-html="$t('views.tools.domain_finder.domain_unclear_note', supported_domains['kit.edu'])"/>
    </b-alert>
    <b-alert
        :show="search_success === false"
        dismissible
        variant="danger">
      {{ $t('views.tools.domain_finder.failed') }}{{ failure_reason }}
    </b-alert>

    <b-form @submit="searchDomain">
      <b-form-group :label="$t('views.tools.domain_finder.domain') + ':'">
        <b-input required v-model="search_domain" :placeholder="$t('views.tools.domain_finder.domain')"
                 :state="is_domain_supported">
        </b-input>
      </b-form-group>
      <b-button type="submit" variant="outline-success" :disabled="!is_domain_supported">
        <netvs-icon icon="search"></netvs-icon>
        {{ $t('views.tools.domain_finder.start_search') }}
      </b-button>
    </b-form>
  </div>
</template>
<script>

import Loading from '@/components/Loading.vue'
import Domain_finderService from '@/api-services/domain_finder.service'
import fqdnutil from '@/util/fqdnutil'

export default {
  name: 'DomainFinder',
  components: {Loading},
  data() {
    return {
      search_domain: '',
      request_pending: false,
      search_success: undefined,
      domain_found: undefined,
      parent_found: undefined,
      present_parent: undefined,
      domain_requestable: undefined,
      domain_searched: undefined,
      failure_reason: '',
      supported_domains: {
        'kit.edu': {contact: 'KNN', email: 'knn@kit.edu', web: 'https://knn.kit.edu'},
        'net.kit.edu': {contact: 'KNN', email: 'knn@kit.edu', web: 'https://knn.kit.edu'},
        'hsg.kit.edu': {contact: 'AStA', email: 'hsg@asta-kit.de', web: 'https://www.asta-kit.de'},
        'partner.kit.edu': {contact: 'SCC', email: 'netvs@scc.kit.edu', web: 'https://www.scc.kit.edu'}
      }
    }
  },
  methods: {
    normalize_fdqn: fqdnutil.normalize_fdqn,
    async searchDomain(ev) {
      ev.preventDefault()
      this.search_success = undefined
      this.domain_found = undefined
      this.parent_found = undefined
      this.present_parent = undefined
      this.failure_reason = ''

      if (!this.is_domain_supported) {
        this.search_success = false
        return true
      }

      this.request_pending = true
      this.domain_searched = this.search_domain.replace(/^(.*)\.$/, '$1')
      Domain_finderService.search_domain(this.$store.state, this.domain_searched).then(r => {
        this.search_success = true
        this.domain_found = r.data.domain_found
        this.parent_found = r.data.parent_found
        this.present_parent = r.data.present_parent
        if (this.present_parent) {
          this.present_parent = this.present_parent.replace(/^(.*)\.$/, '$1')
          this.domain_requestable = this.domain_searched.split(this.present_parent)[0].replace(/^(.*)\.$/, '$1').split('.').at(-1) + '.' + this.present_parent
        }
      }).catch(e => {
        if ('response' in e && e.response.status === 404) {
          this.failure_reason = e.response.data.detail
          this.search_success = false
        } else {
          throw e
        }
      }).finally(() => {
        this.request_pending = false
      })

      return true
    }
  },
  computed: {
    is_requestable() {
      if (!this.domain_searched) {
        return undefined
      }
      return Object.keys(this.supported_domains).some(d => this.domain_searched.endsWith(d))
    },
    is_domain_supported() {
      if (this.search_domain.length === 0) {
        return undefined
      }
      if (!this.search_domain !== '' && fqdnutil.normalize_fdqn(this.search_domain) === '') {
        return false
      }
      return true
    }
  }
}
</script>
